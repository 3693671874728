import './src/styles/global.css';
import netlifyIdentity from 'netlify-identity-widget';

export const onInitialClientRender = () => {
  netlifyIdentity.init();

  setTimeout(function () {
    document.getElementById('___loader').style.display = 'none';
  }, 1);
};

export const onRouteUpdate = () => {
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'none';
  }, 1);
};

export const onRouteUpdateDelayed = () => {
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'flex';
  }, 1);
};
